import React from "react";
import * as FaIcons from "react-icons/fa";
import {
  Customers,
  Dashboard,
  Data,
  Lable,
  Logout,
  Notifications,
  Orders,
  Payments,
  Printer,
  Products,
  Reports,
  Support,
  Temperature,
} from "./../../SvgIcons/allIcons";
export const SidebarData = [
  {
    icon: <Dashboard />,
    title: "sideBar.dashboard",
    path: "/dashboard",
  },
  {
    icon: <Customers />,
    title: "sideBar.userManagement",
    path: "/usermanagement",
  },

  // {
  //   icon: <Payments />,
  //   title: "sideBar.paymentsBilling",
  //   path: "/paymentandbilling",
  // },
  // {
  //   icon: <Orders />,
  //   title: "sideBar.ordersTransactions",
  //   path: "/orderindex",
  // },
  // {
  //   icon: <Products />,
  //   title: "sideBar.products",
  //   path: "/product",
  // },
  // {
  //   icon: <Reports />,
  //   title: "sideBar.reportAnalytics",
  //   path: "/reportsindex",
  // },
  // {
  //   icon: <Lable />,
  //   title: "sideBar.labelManagement",
  //   path: "/product",
  // },
  {
    icon: <Data />,
    title: "sideBar.dataManagement",
    iconClosed: <FaIcons.FaAngleDown />,
    iconOpened: <FaIcons.FaAngleUp />,
    subNav: [
      {
        title: "sideBar.products",
        path: "/product",
      },
      {
        title: "sideBar.prepListBatches",
        path: "/prep-list",
      },
      {
        title: "sideBar.taskList",
        path: "/tasklist",
      },
      {
        title: "sideBar.recivingList",
        path: "/receivinglist",
      },
      {
        title: "sideBar.items",
        path: "/items",
      },
      {
        title: "sideBar.users",
        path: "/userlist",
      },
      {
        title: "sideBar.categories",
        path: "/categorylist",
      },
    ],
  },

  // {
  //   icon: <Printer />,
  //   title: "sideBar.printerManagement",
  //   path: "",
  // },
  // {
  //   icon: <Temperature />,
  //   title: "sideBar.temperatureMonitoring",
  //   path: "",
  // },
  // {
  //   icon: <Notifications />,
  //   title: "sideBar.notificationNews",
  //   path: "/notifications",
  // },
  // {
  //   icon: <Support />,
  //   title: "sideBar.supportTicket",
  //   path: "",
  // },
  {
    icon: <Logout />,
    title: "sideBar.logout",
    type: "logout",
  },
];
