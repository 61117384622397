import React from "react";
import "./notfound.css";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const NotFound = ({ text }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Container fluid>
      <div className="not-found">
        <div className="not_found" data-text={404}>
          <img src={"/images/not-found.gif"} />
        </div>
        <h1 className="mainhead mb-0">{text}</h1>
        <button
          onClick={() => {
            navigate("/");
          }}
          className="userBtn btn-theme mt-4"
        >
          {t("globals.gotoHome")}
        </button>
      </div>
    </Container>
  );
};

export default NotFound;
