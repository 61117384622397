import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import LogoutModal from "../../authentication/LogoutModal";
import useSlider from "../../hooks/useSlider";

const SubMenu = ({ item }) => {
  const { pathname } = useLocation();
  const slider = useSlider();
  const [subnav, setSubnav] = useState(false);
  const [show, setShow] = useState(false);
  const [addClass, setAddClass] = useState("");
  const { t } = useTranslation();
  return (
    <>
      <Link
        onMouseEnter={() => {
          setAddClass("nav-hover");
          if (slider) {
            setSubnav(true);
          }
        }}
        onMouseLeave={() => {
          setAddClass("");
          if (slider) {
            setSubnav(false);
          }
        }}
        to={item.path}
        onClick={(e) => {
          if (!slider && item.subNav) {
            setSubnav(!subnav);
          }
          if (item?.type == "logout") {
            e.preventDefault();
            setShow(true);
          }
        }}
        className={`${slider ? addClass : ""} ${
          item?.path == pathname ? "active" : ""
        }`}
      >
        <span className="value">
          {item.icon}
          <span>{t(item.title)}</span>
        </span>
        <span className="icon">
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </span>
      </Link>

      <div className="subnav">
        {subnav &&
          item?.subNav?.map((item, index) => {
           
            return (
              <Link
                to={item.path}
                key={index}
                className={item?.path == pathname ? "active" : ""}
              >
                {item.icon}
                <span>{t(item.title)}</span>
              </Link>
            );
          })}
      </div>
      <LogoutModal show={show} handleClose={() => setShow(false)} />
    </>
  );
};

export default SubMenu;
