import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Loader from "../../components/Loader/Loader";
import useDetails from "../../hooks/useDetails";
import useSlider from "../../hooks/useSlider";
import { login } from "../../redux/features/authSlice";
import {
  editProfile,
  profileImageUplaod
} from "../../services/services";
import Sidebar from "../sidebar/Sidebar";

const EditProfile = () => {
  const isSlider = useSlider();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const details = useDetails();
  const navigate = useNavigate();

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    setValues,
    handleSubmit,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      profileImg: "",
      newPicked: "",
    },
    validationSchema: yup.object().shape({
      name: yup
        .string()
        .required()
        .label(t("profile.name"))
        .trim()
        .matches(
          /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
          "Name can only contain letters."
        ),
      email: yup.string().required().label(t("profile.emailAddress")).email(),
      newPicked: yup.mixed().when(([newPicked], schema) => {
        if (newPicked) {
          return yup
            .mixed()
            .test(
              "type",
              "Please select jpg, png, jpeg format",
              function (value) {
                return (
                  value &&
                  (value.type === "image/jpg" ||
                    value.type === "image/png" ||
                    value.type === "image/jpeg")
                );
              }
            );
        }
        return schema;
      }),
    }),
    onSubmit: () => {
      if (values?.newPicked) {
        let formData = new FormData();
        formData.append("file", values?.newPicked);
        imageMutation.mutate(formData);
      } else {
        let body = {         
          name: values?.name,          
        };
        mutation.mutate(body);
      }
    },
  });

  useEffect(() => {
    setValues({
      ...values,
      name: details?.userInfo?.name,
      email: details?.email,
      profileImg: details?.userInfo?.profilePhoto,
    });
  }, []);

  const imageMutation = useMutation({
    mutationFn: (formData) => profileImageUplaod(formData),
    onSuccess: (resp) => {
      setFieldValue("profileImg", resp?.data?.data?.profile_photo);
      let body = {       
        name: values?.name,
        profilePhoto: resp?.data?.data?.profile_photo,       
      };
      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: (body) => editProfile(body),
    onSuccess: async () => {
      dispatch(
        login({
          userInfo: {
            ...details?.userInfo,
            name: values?.name,
            profilePhoto: values?.profileImg,
          },
        })
      );
      navigate(-1);
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={12} className="">
            <h2 className="mainhead font-24 myProfile_Txt">
              {" "}
              {t("profile.editProfile")}
            </h2>
          </Col>
        </Row>
        <div className="tableOuter">
          <Row>
            <Col lg={12}>
              <div className="ProfileOuter editProfile">
                <div className="profileImg position-relative">
                  <img
                    src={
                      values.newPicked
                        ? URL.createObjectURL(values.newPicked)
                        : values.profileImg
                        ? values.profileImg
                        : "/images/profile.jpg"
                    }
                    alt="Profile"
                    className="profile_Img img-fluid"
                  />
                  <div className="overlay">
                    <input
                      type="file"
                      hidden
                      id="profile-img"
                      accept="image/*"
                      onChange={(e) =>
                        setFieldValue("newPicked", e.target.files[0])
                      }
                    />

                    <label htmlFor="profile-img">
                      <img
                        src="/images/camera.svg"
                        alt="Camera Logo"
                        className="img-fluid cameraLogo"
                      />
                    </label>
                  </div>
                  <small className="text-danger ms-1">
                    {touched.newPicked && errors.newPicked}
                  </small>
                </div>
                <div className="ProfileInput">
                  <div className="mt-2">
                    <label htmlFor="name" className="companyLabel">
                      {t("profile.name")}
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="commonInput"
                      placeholder={t("profile.enterName")}
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <small className="text-danger ms-1">
                      {touched.name && errors.name}
                    </small>
                  </div>
                  <div className="mt-3">
                    <label htmlFor="name" className="companyLabel">
                      {t("profile.emailAddress")}{" "}
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="commonInput"
                      placeholder={t("profile.enterEmail")}
                      name="email"
                      value={values?.email}
                      readOnly
                    />
                    <small className="text-danger ms-1">
                      {touched.email && errors.email}
                    </small>
                  </div>
                  <button
                    className="userBtn btn-theme mt-4 m-0"
                    onClick={handleSubmit}
                  >
                    {t("profile.saveChanges")}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {(mutation.isPending || imageMutation?.isPending) && <Loader />}
    </div>
  );
};

export default EditProfile;
