import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import useSlider from "../../hooks/useSlider";
import Sidebar from "../sidebar/Sidebar";
import { ArrowLeft } from "../../SvgIcons/allIcons";

const ChooseSize = () => {
  const isSlider = useSlider();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  return (
    <div className="mainbox">
      <Sidebar sideBar={true} />
      <div className={"body-content close aa"}>
        <Row>
          <Col xl={6} className="">
            <h2 className="mainhead font-24">
              {" "}
              <ArrowLeft /> Choose Size
            </h2>
          </Col>
          <Col className="text-end" xl={6}>
            <button className="userBtn btn-theme">Create</button>
          </Col>
        </Row>
        <div className="gridOuter tableOuter">
          <div className="grid">
            <div className="labelCard">
              <div className="labelContent">
                <h3 className="heading colorGreen">1"X1"</h3>
                <p className="font-20 colorGreen">Square</p>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="labelCard">
              <div className="labelContent">
                <h3 className="heading colorGreen">1"X1"</h3>
                <p className="font-20 colorGreen">Square</p>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="labelCard">
              <div className="labelContent">
                <h3 className="heading colorGreen">1"X1"</h3>
                <p className="font-20 colorGreen">Square</p>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="labelCard">
              <div className="labelContent">
                <h3 className="heading colorGreen">1"X1"</h3>
                <p className="font-20 colorGreen">Square</p>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="labelCard">
              <div className="labelContent">
                <h3 className="heading colorGreen">1"X1"</h3>
                <p className="font-20 colorGreen">Square</p>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="labelCard">
              <div className="labelContent">
                <h3 className="heading colorGreen">1"X1"</h3>
                <p className="font-20 colorGreen">Square</p>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="labelCard">
              <div className="labelContent">
                <h3 className="heading colorGreen">1"X1"</h3>
                <p className="font-20 colorGreen">Square</p>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="labelCard">
              <div className="labelContent">
                <h3 className="heading colorGreen">1"X1"</h3>
                <p className="font-20 colorGreen">Square</p>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="labelCard">
              <div className="labelContent">
                <h3 className="heading colorGreen">1"X1"</h3>
                <p className="font-20 colorGreen">Square</p>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="labelCard">
              <div className="labelContent">
                <h3 className="heading colorGreen">1"X1"</h3>
                <p className="font-20 colorGreen">Square</p>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="labelCard">
              <div className="labelContent">
                <h3 className="heading colorGreen">1"X1"</h3>
                <p className="font-20 colorGreen">Square</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseSize;
