import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import {} from "../../SvgIcons/allIcons";
import useSlider from "../../hooks/useSlider";
import Sidebar from "../sidebar/Sidebar";

const UserDetails = () => {
  const isSlider = useSlider();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={12} className="">
            <h2 className="mainhead font-24">User Details</h2>
          </Col>
        </Row>
        <div className="tableOuter">
          <Row className="mb-3">
            <p className="colorDark font-18 mb-2">Basic Info</p>
            <Col className="infoColumn" xl={5}>
              <p className="mb-2">
                Access Level/Role: <span className="colorDark ">Customer</span>
              </p>
            
              <p className="mb-3">
                Last Name: <span className="colorDark">Warren</span>
              </p>
            
              <p className="mb-3">
                Email Address:{" "}
                <span className="colorDark">wadewarren@gmail.com</span>
              </p>
             
              <p className="mb-3">
                Apply to Location:{" "}
                <span className="colorDark">
                  13th Street 47 W 13th St, New York...
                </span>
              </p>
            </Col>

            <Col className="CenterLine" xl={2}></Col>
            <Col className="infoColumn" xl={5}>
            <p className="mb-2">
                First Name: <span className="colorDark">Wade</span>
              </p>
              <p className="mb-3">
                User Name: <span className="colorDark">@wade</span>
              </p>
              <p className="mb-3">
                Phone Number:{" "}
                <span className="colorDark">+1 (051) 417-6501</span>
              </p>
            </Col>
          </Row>
          <Row className="mb-3">
            <p className="colorDark font-18 mb-2">Permissions</p>
            <Col className="infoColumn" xl={5}>
             <ul>
              <li className="mb-3">
              Upload and edit items
              </li>
              <li className="mb-3">
              Upload and edit items
              </li>
              <li className="mb-3">
              Upload and edit items
              </li>
             </ul>
            </Col>

            <Col className="CenterLine" xl={2}></Col>
            <Col className="infoColumn" xl={5}>
            <ul>
              <li className="mb-3">
              Upload and edit items
              </li>
              <li className="mb-3">
              Upload and edit items
              </li>
              <li className="mb-3">
              Upload and edit items
              </li>
             </ul>
            </Col>
          </Row>
      
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
