import React, { useState, useCallback } from "react";
import useSlider from "../../hooks/useSlider";
import Sidebar from "../sidebar/Sidebar";
import { FaAlignLeft } from "react-icons/fa6";
import { FaAlignCenter } from "react-icons/fa6";
import { FaAlignRight, FaAngleUp, FaAngleDown } from "react-icons/fa6";
import { MdOutlineHorizontalRule } from "react-icons/md";
import { useDropzone } from "react-dropzone";
import { UploadIcon , FeatureUploadLogo } from "../../SvgIcons/allIcons";
import Accordion from "react-bootstrap/Accordion";
import "./createLabel.css";

const UploadLogo = () => {
  const isSlider = useSlider();

  const [file, setFile] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    const uploadedFile = acceptedFiles[0];
    setFile(URL.createObjectURL(uploadedFile));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const [sideBar, setSideBar] = useState(false);
  return (
    <div className="mainbox">
      <Sidebar sideBar={!sideBar} />
      <section
        className={`${isSlider ? "body-content close" : "body-content open"} ${
          !sideBar ? "additional-class" : ""
        }`}
      >
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h2 className="main-heading fontSize24">Create Label Profile</h2>
            <div className="all_Customer d-flex align-items-center">
              <div className="form-group">
                <input type="checkbox" id="html1" />
                <label htmlFor="html1">
                  <span className="ms-2 allcustomer_Txt">
                    all our customers
                  </span>
                </label>
              </div>
              <button className="userBtn btn-theme fontSize18">Add User</button>
            </div>
          </div>

          <div className="basic_Info upload_Logo">
            <div>
              <label htmlFor="name" className="companyLabel">
                Profile Name
              </label>
              <input
                type="text"
                id="name"
                className="commonInput fontSize14"
                placeholder="Enter Profile Name"
              />
            </div>

            <div className="snap_Grid_Parent">
              <div className="row">
                <div className="col-md-6">
                  <div className="center d-flex align-items-center">
                    <label htmlFor="grid" className="snapgrid_Label fontSize14">
                      Snap to Grid:
                    </label>
                    <input type="checkbox" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="center d-flex align-items-center">
                    <label htmlFor="grid" className="snapgrid_Label fontSize14">
                      Border:
                    </label>
                    <input type="checkbox" />
                  </div>
                </div>
              </div>
            </div>

            <div className="label_Features">
              <div className="row">
                <div className="col-lg-3 pe-lg-0">
                  <div className="features">
                    <div className="featureTxt_Parent">
                      <h2 className="fontSize24">Features</h2>
                    </div>
                    <div className="feature_Editor_Child features_Accordion_Parent">
                      <div className="features_UploadLogo_Container mb-2">
                        <div className="d-flex  align-items-center">
                          <FeatureUploadLogo/>
                          <span className="fontSize16 ms-2">Upload logo</span>
                        </div>
                        <img
                          src="/images/dropdownListIcon.png"
                          alt="dropdownListIcon"
                        />
                      </div>
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header className="fontSize14">
                            Text
                          </Accordion.Header>
                          <Accordion.Body>
                            <ul className="accordion_List">
                              <li>
                                Item Name{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Price{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Ingredients{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Allergens{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                SKU{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Address{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Employee Name{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Prepped By{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Username{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Description{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Net Weight{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Custom Term{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" className="mt-2">
                          <Accordion.Header>Start Date</Accordion.Header>
                          <Accordion.Body>
                            <ul className="accordion_List">
                              <li>
                                Made{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Pull{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Received{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Received On{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Make{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Prep{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Day, Date, Time{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Kill Date{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Picked On{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Manufactured On{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                MFG{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Opened{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Custom Term{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2" className="mt-2">
                          <Accordion.Header>
                            Transitional Date/Phase
                          </Accordion.Header>
                          <Accordion.Body>
                            <ul className="accordion_List">
                              <li>
                                Ready{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Thaw{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Temper{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Pull{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Marinate{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Thaw Through{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Marinate Until{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Thaw Until{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Custom Term{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3" className="mt-2">
                          <Accordion.Header>End Date</Accordion.Header>
                          <Accordion.Body>
                            <ul className="accordion_List">
                              <li>
                                Must Use By{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Use By{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Discard{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Expires{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Best By{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Freshest By{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Freshest Date{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Sell By{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Best Before{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Must Sell By{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Expire{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Exp{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Custom Term{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4" className="mt-2">
                          <Accordion.Header>End Date</Accordion.Header>
                          <Accordion.Body>
                            <ul className="accordion_List">
                              <li>
                                Nutritional Fields{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Serving size{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Calories{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Total Fat (g){" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Saturated Fat (g){" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Trans Fat (g){" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Cholesterol (mg){" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Carbohydrates (g){" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Protein (g){" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Dietary Fiber (g){" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Total Sugar (g){" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Sodium (mg){" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Servings Per Container{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Added Sugar{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Calories From Fat{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Vitamin D{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Vitamin D%{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Iron{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Potassium{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Potassium %{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5" className="mt-2">
                          <Accordion.Header>Others</Accordion.Header>
                          <Accordion.Body>
                            <ul className="accordion_List">
                              <li>
                                Barcode – Fixed{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Barcode – Variable{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                QR Code{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Custom Field{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Print Sequence{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Custom Text Field{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Waste Prepped Quantity{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                              <li>
                                Prepared Temperature{" "}
                                <img
                                  src="/images/dropdownListIcon.png"
                                  alt="dropdownListIcon"
                                />
                              </li>
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 p-lg-0">
                  <div className="label_Preview features">
                    <div className="featureTxt_Parent d-flex justify-content-between align-items-center">
                      <h2 className="fontSize24">Label Preview</h2>
                      <input type="range" className="range_Input" />
                    </div>
                    <div className="feature_Editor_Child">
                      <div className="label_Preview_Child">
                        <div className="file_Upload mt-3">
                          <div {...getRootProps()} className="dropzone">
                            <input {...getInputProps()} />
                            {!file && !isDragActive && (
                              <p className="text-center">Upload logo here</p>
                            )}
                            {isDragActive ? (
                              <p>Drop the files here ...</p>
                            ) : (
                              file && (
                                <div className="image-preview">
                                  <img
                                    src={file}
                                    alt="Preview"
                                    className="image-preview"
                                  />
                                </div>
                              )
                            )}
                            {!file && !isDragActive && (
                              <button className="userBtn btn-theme fontSize18 uploadFile_Btn">
                                <UploadIcon />{" "}
                                <span className="ms-2">Upload</span>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 ps-lg-0">
                  <div className="feature_Editor features">
                    <div className="featureTxt_Parent">
                      <h2 className="fontSize24">Feature Editor</h2>
                    </div>
                    <div className="feature_Editor_Child">
                      <p className="fontSize14">
                        <span>You are editing:</span> Item Name
                      </p>
                      <div className="center d-flex align-items-center justify-content-between feature_Range">
                        <label
                          htmlFor="grid"
                          className="snapgrid_Label fontSize14"
                        >
                          Snap to Grid:
                        </label>
                        <input type="checkbox" />
                      </div>
                      <div className="feature_Range">
                        <div className="d-flex justify-content-between align-items-center">
                          <span>Min</span>
                          <span>Max</span>
                        </div>
                        <input type="range" className="mt-3" />
                        <div className="d-flex justify-content-between align-items-center mt-2">
                          <h3 className="range_Number fontSize14">15</h3>
                          <h3 className="range_Number fontSize14">150</h3>
                        </div>
                      </div>
                      <div className="feature_Range">
                        <label htmlFor="name" className="companyLabel">
                          Font
                        </label>
                        <select
                          name="state"
                          id="state"
                          className="state_Select"
                        >
                          <option value="Haryana">Calibri</option>
                          <option value="Haryana">Haryana</option>
                          <option value="Punjab">Punjab</option>
                        </select>
                      </div>
                      <div className="feature_Range">
                        <h3 className="fontSize14 mb-2">Text Position</h3>
                        <div className="text_Align_Container">
                          <p className="leftTxt_Icon">
                            <FaAlignLeft />
                          </p>
                          <p className="leftTxt_Icon active">
                            <FaAlignCenter />
                          </p>
                          <p className="leftTxt_Icon">
                            <FaAlignRight />
                          </p>
                        </div>
                      </div>
                      <div className="feature_Range d-flex justify-content-between align-items-center">
                        <h3 className="fontSize14">Bold Text</h3>
                        <h3 className="txt_Bold fontSize24">B</h3>
                      </div>
                      <div className="feature_Range">
                        <h3 className="fontSize14 mb-2">Vertical Alignment</h3>
                        <div className="text_Align_Container">
                          <p className="verticle_Align">
                            <FaAngleDown />
                          </p>
                          <p className="verticle_Align active">
                            <MdOutlineHorizontalRule />
                          </p>
                          <p className="verticle_Align">
                            <FaAngleUp />
                          </p>
                        </div>
                        <div className="mt-3">
                          <label htmlFor="name" className="companyLabel">
                            Font
                          </label>
                          <select
                            name="state"
                            id="state"
                            className="state_Select"
                          >
                            <option value="Haryana">Center</option>
                            <option value="Haryana">Left</option>
                            <option value="Punjab">Right</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UploadLogo;
