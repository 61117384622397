import React from "react";
import useSlider from "../../hooks/useSlider";
import Sidebar from "../sidebar/Sidebar";
import { GrAttachment } from "react-icons/gr";
import { ChatSendIcon } from "../../SvgIcons/allIcons";
import "./supportTickets.css";

const TicketDetails = () => {
  const isSlider = useSlider();

  return (
    <div className="mainbox">
      <Sidebar />
      <section
        className={isSlider ? "body-content close" : "body-content open"}
      >
        <div className="container-fluid">
          <h2 className="main-heading fontSize24">Ticket Details</h2>

          <div className="notifications_Box">
            <div className="d-flex justify-content-between">
              <div>
                <div>
                  <h3 className="ticket_detail fontSize16">Ticket ID:</h3>
                  <span className="main-heading fontSize16">#34567821</span>
                </div>
                <div className="mt-2">
                  <h3 className="ticket_detail fontSize16">User Name:</h3>
                  <span className="main-heading fontSize16">Wade Warren</span>
                </div>
                <div className="mt-2">
                  <h3 className="ticket_detail fontSize16">Email:</h3>
                  <span className="main-heading fontSize16">
                    wadewarren@gmail.com
                  </span>
                </div>
                <div className="mt-2">
                  <h3 className="ticket_detail fontSize16">Title:</h3>
                  <span className="main-heading fontSize16">Prep Guardian</span>
                </div>
              </div>
              <div>
                <button className="userBtn btn-theme fontSize18">
                  MARK AS COMPLETED
                </button>
              </div>
            </div>

            <div className="chatbox_Outer">
              <div className="chatbox_Inner">
                <div className="mess_Parent d-flex align-items-start">
                  <img src="/images/chatReceiverMen.svg" alt="" />
                  <div className="ms-2">
                    <p className="receiver_Name fontSize12">Leslie Alexander</p>
                    <h3 className="receiver_Mess fontSize14">Hi, Jacob</h3>
                    <span className="receiver_Time fontSize12">8:15</span>
                  </div>
                </div>

                <div className="mess_Parent d-flex justify-content-end align-items-start">
                  <div className="me-2 text-end">
                    <p className="receiver_Name fontSize12">Me</p>
                    <h3 className="sender_Mess fontSize14 mb-2">Hello, Leslie Alexander</h3>
                    <h3 className="sender_Mess sender_Mess2 fontSize14">How are you?</h3>
                    <span className="receiver_Time fontSize12">8:15</span>
                  </div>
                  <img src="/images/chatSenderMen.svg" alt="" />
                </div>

                <div className="mess_Parent d-flex align-items-start">
                <img src="/images/chatReceiverMen.svg" alt="" />
                  <div className="ms-2">
                    <p className="receiver_Name fontSize12">Leslie Alexander</p>
                    <h3 className="receiver_Mess fontSize14">I am good</h3>
                    <span className="receiver_Time fontSize12">8:15</span>
                  </div>
                </div>

              </div>
              <div className="d-flex align-items-center">
                <div className="w-100 me-2">
                  <div className="position-relative">
                    <input
                      type="text"
                      id="chat"
                      className="chatsend_Input"
                      placeholder="Type here..."
                    />
                    <GrAttachment
                      color="#41a83a"
                      className="attachement"
                      fontSize={22}
                      fontWeight={600}
                    />
                  </div>
                </div>
                <ChatSendIcon />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TicketDetails;
