import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Stepper } from "react-form-stepper";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import useSlider from "../../hooks/useSlider";
import { addCompany, getOneCompnay } from "../../services/services";
import AdminFooter from "../AdminFooter";
import Sidebar from "../sidebar/Sidebar";
import "./companyManagement.css";

const AddNewCompany = () => {
  const { t } = useTranslation();
  const isSlider = useSlider();
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const id = searchParam?.get("id");
  const header = searchParam?.get("header");
  const [activeStep, setActiveStep] = useState(0);

  useQuery({
    queryKey: ["company", id],
    queryFn: async () => {
      const resp = await (id && getOneCompnay(id));
      setValues({ ...values, companyName: resp?.data?.data?.name });
      return true;
    },
  });

  const mutation = useMutation({
    mutationFn: (body) => addCompany(body),
    onSuccess: (resp) => {
      navigate(
        `../companylocation/${resp?.data?.data?.uuid}?id=${resp?.data?.data?._id}`
      );
      setActiveStep(1);
    },
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
  } = useFormik({
    initialValues: {
      companyName: "",
    },
    validationSchema: yup.object().shape({
      companyName: yup
        .string()
        .required()
        .label(t("addCompany.companyName"))
        .trim(),
    }),

    onSubmit: () => {
      if (id) {
        navigate(`../companylocation/${header}?id=${id}`);
        setActiveStep(1);
      } else {
        mutation.mutate({ name: values?.companyName });
      }
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={6} className="">
            <h2 className="mainhead font-24">
              {t("addCompany.addNewCompany")}
            </h2>
          </Col>
        </Row>

        <div className="basic_Info company-info">
          <Stepper
            steps={[
              { label: t("addCompany.basicInfo") },
              { label: t("addCompany.brandLocation") },
            ]}
            activeStep={activeStep}
          />
          <Form>
            <Row>
              <Col md={12}>
                <div className="mt-2">
                  <label htmlFor="name" className="companyLabel">
                    {t("addCompany.companyName")}{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="companyName"
                    disabled={!!id}
                    value={values?.companyName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="commonInput"
                    placeholder={t("addCompany.enterCompanyName")}
                  />
                  <small className="text-danger ms-1">
                    {touched.companyName && errors.companyName}
                  </small>
                </div>
              </Col>
            </Row>
          </Form>
          <div className="btnGroup">
            <button
              className="userBtn btn-theme ms-auto d-block fontSize18"
              type="button"
              onClick={handleSubmit}
            >
              {t("addCompany.next")}
            </button>
          </div>
        </div>
      </div>
      <AdminFooter />
    </div>
  );
};

export default AddNewCompany;
