import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import useSlider from "../../hooks/useSlider";
import Sidebar from "../sidebar/Sidebar";

const Index = () => {
  const isSlider = useSlider();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={6} className="">
            <h2 className="mainhead font-24">Label Management</h2>
          </Col>
          <Col className="text-end" xl={6}>
            <button className="userBtn btn-theme">Create Label</button>
          </Col>
        </Row>
        <div className="tableOuter">
          <Row>
            <Col className="labelOuter" xl={3}>
              <div className="labelbox">
                <div className="labelImg">
                  <img src="images/label1.png" className="img-fluid"></img>
                </div>
              </div>
              <p className=" labelFooter font-16 colorDark mb-0">1"X1" Square</p>
            </Col>
            <Col className="labelOuter" xl={3}>
              <div className="labelbox">
                <div className="labelImg">
                  <img src="images/label2.png" className="img-fluid"></img>
                </div>
              </div>
              <p className=" labelFooter font-16 colorDark mb-0">2"X1" blank</p>
            </Col>
            <Col className="labelOuter" xl={3}>
              <div className="labelbox">
                <div className="labelImg">
                  <img src="images/label3.png" className="img-fluid"></img>
                </div>
              </div>
              <p className=" labelFooter font-16 colorDark mb-0">1"X1" Square</p>
            </Col>
            <Col className="labelOuter" xl={3}>
              <div className="labelbox">
                <div className="labelImg">
                  <img src="images/label4.png" className="img-fluid"></img>
                </div>
              </div>
              <p className=" labelFooter font-16 colorDark mb-0">1"X1" Square</p>
            </Col>
            <Col className="labelOuter" xl={3}>
              <div className="labelbox">
                <div className="labelImg">
                  <img src="images/label5.png" className="img-fluid"></img>
                </div>
              </div>
              <p className=" labelFooter font-16 colorDark mb-0">1"X1" Square</p>
            </Col>
            <Col className="labelOuter" xl={3}>
              <div className="labelbox">
                <div className="labelImg">
                  <img src="images/label6.png" className="img-fluid"></img>
                </div>
              </div>
              <p className=" labelFooter font-16 colorDark mb-0">1"X1" Square</p>
            </Col>
            <Col className="labelOuter" xl={3}>
              <div className="labelbox">
                <div className="labelImg">
                  <img src="images/label7.png" className="img-fluid"></img>
                </div>
              </div>
              <p className=" labelFooter font-16 colorDark mb-0">1"X1" Square</p>
            </Col>
            <Col className="labelOuter" xl={3}>
              <div className="labelbox">
                <div className="labelImg">
                  <img src="images/label8.png" className="img-fluid"></img>
                </div>
              </div>
              <p className=" labelFooter font-16 colorDark mb-0">1"X1" Square</p>
            </Col>
            <Col className="labelOuter" xl={3}>
              <div className="labelbox">
                <div className="labelImg">
                  <img src="images/label9.png" className="img-fluid"></img>
                </div>
              </div>
              <p className=" labelFooter font-16 colorDark mb-0">1"X1" Square</p>
            </Col>
            <Col className="labelOuter" xl={3}>
              <div className="labelbox">
                <div className="labelImg">
                  <img src="images/label7.png" className="img-fluid"></img>
                </div>
              </div>
              <p className=" labelFooter font-16 colorDark mb-0">1"X1" Square</p>
            </Col>
            <Col className="labelOuter" xl={3}>
              <div className="labelbox">
                <div className="labelImg">
                  <img src="images/label5.png" className="img-fluid"></img>
                </div>
              </div>
              <p className=" labelFooter font-16 colorDark mb-0">1"X1" Square</p>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Index;
