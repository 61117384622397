import { useFormik } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IoChevronBackSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import useSlider from "../../hooks/useSlider";
import Sidebar from "../sidebar/Sidebar";
import { useMutation } from "@tanstack/react-query";
import { addProduct, getCompanyLocation } from "../../services/services";
import useDetails from "../../hooks/useDetails";
import DropDown from "../../components/DropDown";
import { constant } from "../../utils/constants";

const Addproduct = () => {
  const isSlider = useSlider();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const details = useDetails();

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    touched,
  } = useFormik({
    initialValues: {
      itemName: "",
      itemCode: "",
      labelPerRoll: "",
      rollPack: "",
      price: "",
      customer: "",
      locations: [],
      allCustomer: false,
    },
    validationSchema: yup.object().shape({
      itemName: yup.string().required().label(t("product.itemName")).trim(),
      itemCode: yup
        .string()
        .required()
        .label(t("product.itemCode"))
        .trim()
        .matches(/^[a-zA-Z0-9]*$/, t("errorMsg.invalidZipCode")),
      labelPerRoll: yup
        .number()
        .required()
        .min(1)
        .positive()
        .typeError(t("errorMsg.invalidNumber"))
        .label(t("product.labelPerRoll")),
      rollPack: yup
        .number()
        .required()
        .min(1)
        .positive()
        .required()
        .label(t("product.rollPack"))
        .typeError(t("errorMsg.invalidNumber")),
      price: yup
        .number()
        .required()
        .min(1)
        .positive()
        .required()
        .label(t("product.price"))
        .typeError(t("errorMsg.invalidNumber")),
      customer: yup.string().required().label(t("product.customer")).trim(),
      locations: yup.array().min(1).label(t("prepList.prepLocation")),
    }),
    onSubmit: (values) => {
      let body = {
        name: values?.itemName,
        // item: values?.itemCode,
        labelPerRoll: values?.labelPerRoll,
        rollsPerPack: values?.rollPack,
        price: values?.price,
        // customerId: values?.customer,
        forAllCustomers: values?.allCustomer,
        locations: values?.locations?.map((i) => i.value),
      };

      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: (body) => addProduct(body, details?.companyUID),
    onSuccess: () => {
      navigate("../product");
    },
  });

  const loadLocations = async (search, loadedOptions, { page }) => {
    let resp = await getCompanyLocation(
      details?.companyUID,
      page,
      constant.PER_PAGE_TEN,
      search
    );

    let array = (await resp?.data?.data?.locations?.locations) ?? [];
    return {
      options: array.map((item) => ({
        value: item?._id,
        label: item?.name,
      })),
      hasMore:
        loadedOptions.length == resp?.data?.data?.locations?.total
          ? false
          : true,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={12}>
            <h2 className="mainhead font-24" onClick={() => navigate(-1)}>
              <IoChevronBackSharp size={30} />
              {t("product.addNewProduct")}
            </h2>
          </Col>
        </Row>
        <div className="basic_Info">
          <Row>
            <Col md={6}>
              <div className="mt-2">
                <label htmlFor="itemName" className="companyLabel">
                  {t("product.itemName")} <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="itemName"
                  name="itemName"
                  value={values?.itemName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="commonInput"
                  placeholder={t("product.enterItemName")}
                />
                <small className="text-danger ms-1">
                  {touched.itemName && errors.itemName}
                </small>
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-2">
                <label htmlFor="itemCode" className="companyLabel">
                  {t("product.itemCode")} <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="itemCode"
                  name="itemCode"
                  value={values?.itemCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="commonInput"
                  placeholder={t("product.enterItemCode")}
                />
                <small className="text-danger ms-1">
                  {touched.itemCode && errors.itemCode}
                </small>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="mt-3">
                <label htmlFor="labelPerRoll" className="companyLabel">
                  {t("product.labelPerRoll")}{" "}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="labelPerRoll"
                  name="labelPerRoll"
                  value={values?.labelPerRoll}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="commonInput"
                  placeholder={t("product.enterLabelPerRoll")}
                />
                <small className="text-danger ms-1">
                  {touched.labelPerRoll && errors.labelPerRoll}
                </small>
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-3">
                <label htmlFor="rollPack" className="companyLabel">
                  {t("product.rollPack")} <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="rollPack"
                  name="rollPack"
                  value={values?.rollPack}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="commonInput"
                  placeholder={t("product.enterRollPack")}
                />
                <small className="text-danger ms-1">
                  {touched.rollPack && errors.rollPack}
                </small>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="position-relative mt-3">
                <label htmlFor="price" className="companyLabel">
                  {t("product.price")} <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="price"
                  name="price"
                  value={values?.price}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="commonInput"
                  placeholder={t("product.enterPrice")}
                />
                <small className="text-danger ms-1">
                  {touched.price && errors.price}
                </small>
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-3">
                <label htmlFor="customer" className="companyLabel">
                  {t("product.customer")} <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="customer"
                  name="customer"
                  value={values?.customer}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="commonInput"
                  placeholder={t("product.enterCustomer")}
                />
                <small className="text-danger ms-1">
                  {touched.customer && errors.customer}
                </small>
              </div>
            </Col>
            <Col md={6}>
              <div className="mt-3">
                <label htmlFor="location" className="companyLabel">
                  {t("product.location")} <span className="text-danger">*</span>
                </label>

                <DropDown
                  placeholder={t("product.enterLocation")}
                  id="locations"
                  loadOptions={loadLocations}
                  isClearable={false}
                  isMulti={true}
                  value={values?.locations}
                  onChange={(e) => setFieldValue("locations", e)}
                  onBlur={() => setFieldTouched("locations", true)}
                />
                <small className="text-danger ms-1">
                  {touched?.locations && errors?.locations}
                </small>
              </div>
            </Col>
            <div className="customer_Access">
              <div className="access_Child">
                <div className="form-group">
                  <input
                    type="checkbox"
                    id="allCustomer"
                    name="allCustomer"
                    value={values?.allCustomer}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values?.allCustomer === true}
                  />
                  <label htmlFor="allCustomer">
                    <span className="fontSize14 ms-2">
                      {t("product.allCustomers")}
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <Row>
              <Col className="mt-4" xl={12}>
                <button
                  className="userBtn btn-theme m-0"
                  type="button"
                  onClick={handleSubmit}
                >
                  {t("globals.add")}
                </button>
              </Col>
            </Row>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Addproduct;
