import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { forgetPass } from "../services/services";
import "./auth-responsive.css";
import "./auth.css";
import { toastAlert } from "../utils/SweetAlert";
import Loader from "../components/Loader/Loader";

const Forget = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object().shape({
      email: yup.string().email().required().label(t("forgot.email")).trim(),
    }),
    onSubmit: (values) => {
      let body = {
        email: values?.email,
      };

      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: (body) => forgetPass(body),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      navigate("/otp");
      resetForm();
    },
  });

  return (
    <section className="auth-main">
      <Container fluid className="p-0">
        <Row className="m-0 ">
          <Col lg={6} className="px-0">
            <div className="auth_img_wrp">
              <img src="/images/auth-img.png" alt="img" />
            </div>
          </Col>
          <Col lg={6}>
            <div className="auth-card">
              <div className="auth_logo">
                <Link to="/">
                  <img src="images/logo.svg" alt="" />{" "}
                </Link>
              </div>
              <div className="auth_heading">
                <h1>{t("forgot.forgotPassword")}</h1>
                <p className="mb-0">{t("forgot.enterEmailAddress")}</p>
              </div>
              <Form>
                <Row>
                  <Col lg={12}>
                    <Form.Group className="mb-5 loginInput">
                      <label>{t("forgot.email")}</label>
                      <div className="position-relative">
                        <img
                          className="inputicon"
                          src="/images/message.svg"
                        ></img>
                        <Form.Control
                          type="text"
                          placeholder={t("forgot.enterEmail")}
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              handleSubmit();
                            }
                          }}
                        />
                      </div>

                      <small className="text-danger">
                        {touched.email && errors.email}
                      </small>
                    </Form.Group>
                  </Col>

                  <Col lg={12} className="text-end">
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="btn-theme "
                    >
                      {t("forgot.submit")}
                    </button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      {mutation.isPending && <Loader />}
    </section>
  );
};

export default Forget;
