import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import useSlider from "../../hooks/useSlider";
import Pagination from "../../utils/Pagination";
import Sidebar from "../sidebar/Sidebar";
import { useTranslation } from "react-i18next";
import { deleteCatergory, getCategory } from "../../services/services";
import { useMutation, useQuery } from "@tanstack/react-query";
import { constant } from "../../utils/constants";
import DeleteModal from "../../components/DeleteModal";
import { toastAlert } from "../../utils/SweetAlert";
import useDetails from "../../hooks/useDetails";

const CategoryList = () => {
  const isSlider = useSlider();
  const { t } = useTranslation();
  const details = useDetails();
  const [page, setPage] = useState(constant.PAGE_ONE);
  const [limit, setLimit] = useState(constant.PER_PAGE_TEN);
  const [showDelete, setShowDelete] = useState(false);

  const { data, refetch } = useQuery({
    queryKey: ["category-list", page, limit],
    queryFn: async () => {
      const resp = await getCategory(details?.companyUID, page, limit);
      return resp?.data?.data;
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => deleteCatergory(details?.companyUID, id),
    onSuccess: (resp) => {
      refetch();
      setShowDelete(false);
      toastAlert("success", resp?.data?.message);
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={6} className="">
            <h2 className="mainhead font-24">{t("category.categories")}</h2>
          </Col>
          <Col className="text-end" xl={6}>
            <button className="userBtn btn-theme me-2">
              {t("globals.export")}
            </button>
            <Link to="../addcategory" className="userBtn btn-theme">
              {t("globals.addNew")}
            </Link>
          </Col>
        </Row>
        <div className="table-responsive tableOut mt-4">
          <table className="table tableDashboard">
            <thead className="thead-dark">
              <tr>
                <th>#</th>
                <th>{t("category.categoryIcon")}</th>
                <th>{t("category.categoryName")}</th>
                <th>{t("category.categoryNameSpanish")}</th>
                <th>{t("category.location")}</th>
                <th>{t("globals.action")}</th>
              </tr>
            </thead>
            <tbody>
              {data?.categories && data?.categories?.length > 0 ? (
                data?.categories?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{(page - 1) * limit + (index + 1)}</td>
                      <td>
                        <img
                          src={item?.icons ? item?.icons : "/images/dummy.jpg"}
                          alt="cat-icon"
                          width={50}
                          height={50}
                        />
                      </td>
                      <td>{item?.es_name}</td>
                      <td>{item?.en_name}</td>
                      <td>
                        {item?.assignAll
                          ? t("globals.allLocations")
                          : item?.locations?.map((i) => i.name)?.join(", ")}
                      </td>
                      <td>
                        <Link to={`../addcategory?id=${item?._id}`}>
                          <img
                            src="/images/Edit.svg"
                            className="img-fluid actionIcon"
                          />
                        </Link>
                        <img
                          src="/images/Delete.svg "
                          className="img-fluid actionIcon"
                          onClick={() => setShowDelete(item?._id)}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    <h4>{t("category.notFound")}</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalPages={data?.total_pages}
          total={data?.total}
          length={data?.categories?.length}
        />
        <DeleteModal
          show={showDelete}
          handleClose={() => setShowDelete(false)}
          handleDelete={deleteMutation.mutate}
        />
      </div>
    </div>
  );
};

export default CategoryList;
