import { useEffect, useState } from "react";
import useDetails from "../hooks/useDetails";

const CheckPermission = ({ children, permission }) => {
  const [hasPermission, setHasPermission] = useState(false);
  const details = useDetails();

  useEffect(() => {
    const permissionStatus = details?.userPermissions[permission];
    setHasPermission(permissionStatus);
  }, [details?.userPermission, permission]);

  return hasPermission ? children : null;
};
export default CheckPermission;
