import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import useSlider from "../../hooks/useSlider";
import Sidebar from "../sidebar/Sidebar";
import "./notification.css";

const Notification = () => {
  const isSlider = useSlider();
  const { t } = useTranslation();

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      notificationTo: 1,
      notificationVia: 1,
      subject: "",
      description: "",
    },
    validationSchema: yup.object().shape({
      subject: yup.string().required().label(t("notification.subject")),
    }),
    onSubmit: (values) => {
      console.log("values", values);
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <section
        className={isSlider ? "body-content close" : "body-content open"}
      >
        <div className="container-fluid">
          <h2 className="main-heading fontSize24">
            {t("notification.notificationNews")}
          </h2>

          <div className="notifications_Box">
            <div>
              <h3 className="fontSize14">
                {t("notification.sendNoticicationTo")}:
              </h3>
              <div className="select_Role d-flex align-items-center flex-wrap">
                <div>
                  <input
                    type="radio"
                    id="allResturant"
                    name="notificationTo"
                    value={1}
                    checked={values?.notificationTo == 1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="allResturant" className="position_Label free">
                    {t("notification.allRestaurants")}
                  </label>
                </div>
                <div className="positionLabel_Parent">
                  <input
                    type="radio"
                    id="selectedResturants"
                    name="notificationTo"
                    value={2}
                    checked={values?.notificationTo == 2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label
                    htmlFor="selectedResturants"
                    className="position_Label"
                  >
                    {t("notification.selectedResturants")}
                  </label>
                </div>
              </div>
            </div>

            {/* <div className="mt-4">
              <label htmlFor="name" className="companyLabel">
              Select Restaurants
              </label>
              <select name="state" id="state" className="state_Select">
                <option value="Haryana">Search or Select Restaurants</option>
                <option value="Haryana">Restaurants</option>
                <option value="Punjab">Restaurants</option>
              </select>
            </div> */}

            <div className="sendnotification_Parent">
              <h3 className="fontSize14">
                {t("notification.sendNotificationVia")}:
              </h3>
              <div className="select_Role d-flex align-items-center">
                <div>
                  <input
                    type="radio"
                    id="push"
                    name="notificationVia"
                    value={1}
                    checked={values?.notificationVia == 1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="push" className="position_Label free">
                    {t("notification.push")}
                  </label>
                </div>
                <div className="positionLabel_Parent">
                  <input
                    type="radio"
                    id="email"
                    name="notificationVia"
                    value={2}
                    checked={values?.notificationVia == 2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="email" className="position_Label">
                    {t("notification.email")}
                  </label>
                </div>
              </div>
            </div>

            <div>
              <div className="createPlanform_Child">
                <label htmlFor="subject" className="companyLabel">
                  {t("notification.subject")}{" "}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="subject"
                  className="commonInput"
                  placeholder={t("notification.enterSubject")}
                  name="subject"
                  value={values?.subject}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <small className="text-danger ms-1">
                  {touched.subject && errors.subject}
                </small>
              </div>
              <div className="createPlanform_Child">
                <label htmlFor="name" className="companyLabel">
                  {t("notification.description")}
                </label>
                <textarea
                  id="name"
                  className="commonInput"
                  placeholder={t("notification.writeHere")}
                  rows="4"
                  cols="50"
                  name="description"
                  value={values?.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></textarea>
              </div>
            </div>

            <div className="notificationSend_Btn">
              <button
                className="userBtn btn-theme fontSize18"
                onClick={handleSubmit}
                type="button"
              >
                {t("notification.send")}
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Notification;
