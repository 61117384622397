import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import NavigateBack from "../../components/NavigateBack";
import useSlider from "../../hooks/useSlider";
import Sidebar from "../sidebar/Sidebar";
import { useQuery } from "@tanstack/react-query";
import { getDataListbyId } from "../../services/services";
import { useParams } from "react-router-dom";
import useDetails from "../../hooks/useDetails";
import moment from "moment";

const CompleteTaskdetail = () => {
  const isSlider = useSlider();
  const { t } = useTranslation();
  const details = useDetails();
  const { id } = useParams();

  const { data } = useQuery({
    queryKey: ["receiving-details", id],
    queryFn: async () => {
      const resp =
        !!id && (await getDataListbyId(id, details?.companyUID, true, true));
      let data = resp?.data?.data;
      return data;
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={6}>
            <NavigateBack>{t("task.taskDetails")}</NavigateBack>
          </Col>
          <Col className="text-end" xl={6}>
            {true ? (
              <button className="userBtn btn-theme">
                {t("task.markAsCompleted")}
              </button>
            ) : (
              <button className="userBtn btn-theme">
                {t("task.completed")}
              </button>
            )}
          </Col>
        </Row>
        <div className="tableOuter">
          <Row className="mb-3">
            <p className="colorDark font-18 mb-2"> {t("task.basicInfo")}</p>
            <Col className="infoColumn" xl={5}>
              <p>
                {t("task.taskListName")}:{" "}
                <span className="colorDark">{data?.name}</span>
              </p>
              <p>
                {t("task.employeeName")}:{" "}
                <span className="colorDark">
                  {data?.customerDetails?.userInfo?.name}
                </span>
              </p>
              <p>
                {t("task.startTime")}:{" "}
                <span className="colorDark">
                  {data?.details?.startTime &&
                    moment(data?.details?.startTime).format("ll")}
                </span>
              </p>
              <p>
                Task Location:{" "}
                <span className="colorDark">
                  {data?.locations?.map((i) => i.name)?.join(", ")}
                </span>
              </p>
            </Col>

            <Col className="CenterLine" xl={2}></Col>
            <Col className="infoColumn" xl={5}>
              <p>
                {t("task.taskName")}:{" "}
                <span className="colorDark">{data?.details?.taskName}</span>
              </p>
              <p>
                {t("task.createdOn")}:{" "}
                <span className="colorDark">
                  {moment(data?.createdAt).format("lll")}
                </span>
              </p>
              <p>
                {t("task.endTime")}:{" "}
                <span className="colorDark">
                  {data?.details?.end
                    ? moment(data?.details?.end).format("ll")
                    : "--"}
                </span>
              </p>
              {/* <p>
                {t("task.comment")}: <span className="colorDark">20</span>
              </p> */}
            </Col>
          </Row>
          {false && (
            <Row className="mb-3">
              <p className="colorDark font-18 mb-2">{t("task.ReportInfo")}</p>
              <Col className="infoColumn" xl={5}>
                <p>
                  {t("task.whenWasItCompleted")}:{" "}
                  <span className="colorDark">13/06/2024</span>
                </p>
                <p>
                  {t("task.howLongDidItTake")}::{" "}
                  <span className="colorDark">2 Weeks</span>
                </p>
              </Col>

              <Col className="CenterLine" xl={2}></Col>
              <Col className="infoColumn" xl={5}>
                <p>
                  {t("task.whoCompletedIt")}:{" "}
                  <span className="colorDark">Wade Warren</span>
                </p>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompleteTaskdetail;
