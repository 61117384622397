import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Search } from "../../SvgIcons/allIcons";
import Sidebar from "../sidebar/Sidebar";
import useSlider from "../../hooks/useSlider";
import { HideIcon, PlusIcon, CrossIcon } from "../../SvgIcons/allIcons";
import Pagination from "../../utils/Pagination";

const Orderindex = () => {
  const isSlider = useSlider();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={12} className="">
            <h2 className="mainhead font-24">Orders/Transactions</h2>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <div className="sidebarBox mt-3">
              <div className="sidebar-box search-form-wrap mb-0">
                <form  className="search-form">
                  <div className="form-group">
                    <span className="iconsearch">
                      <Search />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      id="s"
                      placeholder="Search..."
                    />
                  </div>
                </form>
              </div>
            </div>
          </Col>
        </Row>
        <div className="table-responsive tableOut mt-4">
          <table className="table tableDashboard">
            <thead className="thead-dark">
              <tr>
                <th >Order ID</th>
                <th >User Name</th>
                <th >Amount</th>
                <th >Date</th>
                <th >Time</th>
                <th >Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>#34567821</td>
                <td>Wade Warren</td>
                <td>$500</td>
                <td>05/29/2024</td>
                <td>12:00 PM</td>
                <td><p className="greenBox">Completed</p></td>
              </tr>
              <tr>
                <td>#34567821</td>
                <td>Wade Warren</td>
                <td>$500</td>
                <td>05/29/2024</td>
                <td>12:00 PM</td>
                <td><p className="greenBox redBox">Cancelled</p></td>
              </tr>
              <tr>
                <td>#34567821</td>
                <td>Wade Warren</td>
                <td>$500</td>
                <td>05/29/2024</td>
                <td>12:00 PM</td>
                <td><p className="greenBox yellowBox">Pending</p></td>
              </tr>
              <tr>
                <td>#34567821</td>
                <td>Wade Warren</td>
                <td>$500</td>
                <td>05/29/2024</td>
                <td>12:00 PM</td>
                <td><p className="greenBox">Completed</p></td>
              </tr>
              <tr>
                <td>#34567821</td>
                <td>Wade Warren</td>
                <td>$500</td>
                <td>05/29/2024</td>
                <td>12:00 PM</td>
                <td><p className="greenBox redBox">Cancelled</p></td>
              </tr>
              <tr>
                <td>#34567821</td>
                <td>Wade Warren</td>
                <td>$500</td>
                <td>05/29/2024</td>
                <td>12:00 PM</td>
                <td><p className="greenBox yellowBox">Pending</p></td>
              </tr>
              <tr>
                <td>#34567821</td>
                <td>Wade Warren</td>
                <td>$500</td>
                <td>05/29/2024</td>
                <td>12:00 PM</td>
                <td><p className="greenBox">Completed</p></td>
              </tr>
              <tr>
                <td>#34567821</td>
                <td>Wade Warren</td>
                <td>$500</td>
                <td>05/29/2024</td>
                <td>12:00 PM</td>
                <td><p className="greenBox redBox">Cancelled</p></td>
              </tr>
              <tr>
                <td>#34567821</td>
                <td>Wade Warren</td>
                <td>$500</td>
                <td>05/29/2024</td>
                <td>12:00 PM</td>
                <td><p className="greenBox yellowBox">Pending</p></td>
              </tr>
              <tr>
                <td>#34567821</td>
                <td>Wade Warren</td>
                <td>$500</td>
                <td>05/29/2024</td>
                <td>12:00 PM</td>
                <td><p className="greenBox">Completed</p></td>
              </tr>
              <tr>
                <td>#34567821</td>
                <td>Wade Warren</td>
                <td>$500</td>
                <td>05/29/2024</td>
                <td>12:00 PM</td>
                <td><p className="greenBox redBox">Cancelled</p></td>
              </tr>
              <tr>
                <td>#34567821</td>
                <td>Wade Warren</td>
                <td>$500</td>
                <td>05/29/2024</td>
                <td>12:00 PM</td>
                <td><p className="greenBox yellowBox">Pending</p></td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination/>
      </div>
    </div>
  );
};

export default Orderindex;
