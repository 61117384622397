import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import OTPInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { resendOtp, verifyOtp } from "../services/services";
import { toastAlert } from "../utils/SweetAlert";
import "./auth-responsive.css";
import "./auth.css";
import { constant } from "../utils/constants";
import Loader from "../components/Loader/Loader";

const Enterotp = () => {
  const [timer, setTimer] = useState(60); // Initial timer value in seconds
  const [otpSent, setOtpSent] = useState(false); // State to track if OTP has been sent
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { values, errors, setFieldValue, handleSubmit, resetForm } = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: yup.object().shape({
      otp: yup.string().required().label(t("otp.otp")).length(4),
    }),
    onSubmit: (values) => {
      let body = {
        otp: values?.otp,
      };
      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: (body) => verifyOtp(body),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      navigate(`/reset-password/${resp?.data?.data?.user_id}`);
      resetForm();
    },
  });

  const resendOtpMutation = useMutation({
    mutationFn: () => resendOtp({ email: constant.ADMIN_EMAIL, type: "forgot" }),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      setOtpSent(true);
    },
  });

  useEffect(() => {
    let interval = null;

    // Countdown timer effect
    if (otpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
      setOtpSent(false); // Reset state when timer reaches 0
      setTimer(60); // Reset timer
    }

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [otpSent, timer]);

  return (
    <section className="auth-main">
      <Container fluid className="p-0">
        <Row className="m-0 ">
          <Col lg={6} className="px-0">
            <div className="auth_img_wrp">
              <img src="/images/auth-img.png" alt="img" />
            </div>
          </Col>
          <Col lg={6}>
            <div className="auth-card">
              <div className="auth_logo">
                <Link to="/">
                  <img src="images/logo.svg" alt="" />{" "}
                </Link>
              </div>
              <div className="auth_heading">
                <h1>{t("otp.enterCode")}</h1>
                <p className="mb-0">{t("otp.fourDigitCode")}</p>
              </div>
              <Col className="otpFeild" lg={12}>
                <OTPInput
                  value={values?.otp}
                  onChange={(e) => setFieldValue("otp", e)}
                  numInputs={4}
                  renderSeparator={<span></span>}
                  renderInput={(props) => <input {...props} />}
                  inputType="number"
                />
                <small className="text-danger">{errors.otp}</small>
              </Col>

              <Col lg={12} className="text-end mt-4">
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="btn-theme "
                >
                  {t("otp.verify")}
                </button>
              </Col>

              <Col lg={12}>
                <p className="mt-4">
                  {t("otp.dontReceived")}{" "}
                  {otpSent ? (
                    t("otp.resendOtpIn", { timer })
                  ) : (
                    <Link
                      className="linktext"
                      onClick={(e) => {
                        e.preventDefault();
                        resendOtpMutation.mutate();
                      }}
                    >
                      {t("otp.resend")}
                    </Link>
                  )}
                </p>
              </Col>
            </div>
          </Col>
        </Row>
      </Container>
      {(mutation.isPending || resendOtpMutation.isPending) && <Loader />}
    </section>
  );
};

export default Enterotp;
