import React from "react";
import { useTranslation } from "react-i18next";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { constant } from "./constants";

const Pagination = (props) => {
  const { t } = useTranslation();
  return (
    <div className="paginationOuter p-2">
      <div className="d-flex align-items-center justify-content-between m-2">
        <div>
          <label className="colorLight font-13">
            {t("globals.itemPerPage")}
          </label>
          <select
            className="paginationInner"
            value={props?.limit}
            onChange={(e) => {
              props.setLimit(e.target.value);
              props?.setPage(constant.PAGE_ONE);
            }}
          >
            <option value={constant.PER_PAGE_TEN}>10</option>
            <option value={constant.PER_PAGE_TWENTY}>20</option>
          </select>
          <label className="colorLight font-13">
            {(props?.page - 1) * props?.limit + 1} -{" "}
            {(props?.page - 1) * props?.limit + props.length} of {props?.total}
          </label>
        </div>
        <div>
          <ReactPaginate
            previousLabel={
              <>
                <FaLessThan />
                <span>{t("globals.prev")}</span>
              </>
            }
            nextLabel={
              <>
                <span>{t("globals.next")}</span>
                <FaGreaterThan />
              </>
            }
            className={props?.totalPages > 1 ? "" : "d-none"}
            breakLabel={"..."}
            breakClassName={"page-item"}
            forcePage={props?.page ? props?.page - 1 : false}
            pageCount={Math.ceil(props?.totalPages ?? 1)}
            marginPagesDisplayed={3}
            onPageChange={(e) => props?.setPage(e.selected + 1)}
            renderOnZeroPageCount={null}
            containerClassName={"pagination position-relative mt-2 pt-3"}
            pageClassName={"page-item"}
            previousClassName={`page-item`}
            previousLinkClassName={"pagination__link"}
            nextClassName={"page-item"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
            breakLinkClassName={"page-link"}
          />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
