import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import DeleteModal from "../../components/DeleteModal";
import Loader from "../../components/Loader/Loader";
import useSlider from "../../hooks/useSlider";
import { deleteCompanyBrand, getCompanyBrands } from "../../services/services";
import { toastAlert } from "../../utils/SweetAlert";
import AdminFooter from "../AdminFooter";
import Sidebar from "../sidebar/Sidebar";
import "./companyManagement.css";

const CompanyDetails = () => {
  const [showDelete, setShowDelete] = useState(false);
  const { t } = useTranslation();
  const { header } = useParams();
  const isSlider = useSlider();

  const { data, refetch } = useQuery({
    queryKey: ["company-details", header],
    queryFn: async () => {
      const resp = await getCompanyBrands(header);
      return resp?.data?.data;
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => deleteCompanyBrand(id, header),
    onSuccess: (resp) => {
      refetch();
      setShowDelete(false);
      toastAlert("success", resp?.data?.message);
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h2 className="main-heading fontSize24">
            {t("companyManagement.companyDetails")}
          </h2>
        </div>
        <div className="basic_Info company-info">
          <div>
            <p className="font-18 colorDark mb-3">
              {t("companyManagement.companyName")}:{" "}
              <span className="colorLight ms-2">{data?.name}</span>
            </p>
            <p className="font-18 colorDark">{t("companyManagement.brands")}</p>
          </div>
          <div className="table-responsive companyManagTable tableOut mt-4">
            <table className="table tableDashboard">
              <thead className="thead-dark">
                <tr>
                  <th>{t("companyManagement.name")}</th>
                  <th>{t("companyManagement.location")}</th>
                  <th>{t("globals.createdAt")}</th>
                  <th>{t("globals.action")}</th>
                </tr>
              </thead>
              <tbody>
                {data?.associatedBrands &&
                data?.associatedBrands?.length > 0 ? (
                  data?.associatedBrands?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item?.brand?.name}</td>
                        <td>{item?.locations?.length}</td>
                        <td>{moment(item?.createdAt).format("lll")}</td>
                        <td>
                          <Link to={`../brand/${item?._id}/${data?.uuid}`}>
                            <img
                              src="/images/eye.svg"
                              className="img-fluid actionIcon"
                            />
                          </Link>
                          <Link
                            to={`../companylocation/${data?.uuid}?id=${data?._id}`}
                          >
                            <img
                              src="/images/Edit.svg"
                              className="img-fluid actionIcon"
                            />
                          </Link>

                          <img
                            src="/images/Delete.svg "
                            className="img-fluid actionIcon"
                            onClick={() => setShowDelete(item?._id)}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center">
                      <h4>{t("globals.noDataFound")}</h4>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <AdminFooter />
      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={deleteMutation.mutate}
      />
    </div>
  );
};

export default CompanyDetails;
