/**
 *
 * @param {*} j end of the loop
 * @returns
 */
export function generateDayHourMinutes(j) {
  const result = [];
  for (let i = 0; i <= j; i++) {
    result.push({
      label: i,
      value: i,
    });
  }
  return result;
}

export const foodUnits = [
    { label: 'Gram', value: 'g' },
    { label: 'Kilogram', value: 'kg' },
    { label: 'Milligram', value: 'mg' },
    { label: 'Ounce', value: 'oz' },
    { label: 'Pound', value: 'lb' },
    { label: 'Ton', value: 'ton' },
    
    { label: 'Liter', value: 'l' },
    { label: 'Milliliter', value: 'ml' },
    { label: 'Gallon', value: 'gal' },
    { label: 'Quart', value: 'qt' },
    { label: 'Pint', value: 'pt' },
    { label: 'Cup', value: 'cup' },
    { label: 'Fluid Ounce', value: 'fl oz' },
    { label: 'Tablespoon', value: 'tbsp' },
    { label: 'Teaspoon', value: 'tsp' },
  
    { label: 'Piece', value: 'piece' },
    { label: 'Slice', value: 'slice' },
    { label: 'Stick', value: 'stick' },
    { label: 'Pinch', value: 'pinch' },
    { label: 'Dash', value: 'dash' },
  
    { label: 'Dozen', value: 'dozen' },
    { label: 'Pack', value: 'pack' },
    { label: 'Bag', value: 'bag' },
    { label: 'Box', value: 'box' },
    { label: 'Bottle', value: 'bottle' },
    { label: 'Can', value: 'can' },
    { label: 'Jar', value: 'jar' },
    { label: 'Carton', value: 'carton' },
  
    { label: 'Tray', value: 'tray' },
    { label: 'Barrel', value: 'barrel' },
    { label: 'Bundle', value: 'bundle' },
    { label: 'Roll', value: 'roll' },
  
    { label: 'Head', value: 'head' },  // Common for lettuce, cabbage, etc.
    { label: 'Bunch', value: 'bunch' }, // Common for herbs, bananas, etc.
    { label: 'Clove', value: 'clove' }, // Common for garlic
    { label: 'Stalk', value: 'stalk' }, // Common for celery
  ];