import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useSlider from "../../hooks/useSlider";
import {
  AddIcon,
  CheckMark,
  CloseIcon,
  CrossIcon,
} from "../../SvgIcons/allIcons";
import Sidebar from "../sidebar/Sidebar";
import "./paymentAndBilling.css";
import { useFormik } from "formik";
import * as yup from "yup";

const PaymentAndBilling = () => {
  const isSlider = useSlider();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { t } = useTranslation();

  const [editPlanShow, setEditPlanShow] = useState(false);
  const handleEditPlanClose = () => setEditPlanShow(false);
  const handleEditPlanShow = () => setEditPlanShow(true);

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    resetForm,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      planName: "",
      price: "",
      addOn: [{ name: "" }],
    },
    validationSchema: yup.object().shape({
      planName: yup.string().required().label(t("payment.planName")).trim(),
      price: yup.number().required().min(1).label(t("payment.price")),
      addOn: yup.array().of(
        yup.object().shape({
          name: yup.string().required().label(t("payment.addOn")).trim(),
        })
      ),
    }),
    onSubmit: (values) => {
      console.log("values", values);
    },
  });

  const handleAddOn = (event, index) => {
    let data = [...values?.addOn];
    data[index][event.target.name] = event.target.value;
    setFieldValue("addOn", data);
  };

  return (
    <div className="mainbox">
      <Sidebar />
      <section
        className={isSlider ? "body-content close" : "body-content open"}
      >
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h2 className="main-heading fontSize24">
              {t("payment.paymentBilling")}
            </h2>
            <button
              className="userBtn btn-theme fontSize18"
              onClick={handleShow}
            >
              {t("payment.createNew")}
            </button>
          </div>

          <Row>
            <Col md={12}>
              <div>
                <div className="plan-parent d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="plan_Txt fontSize20">plan 1</h3>
                    <h3 className="freeTxt fontSize24">Free</h3>
                    <p className="fontSize16">Last Updated: 10 Jun 2023</p>
                  </div>
                  <div className="includes_Parent">
                    <h3 className="includerTxt fontSize14">INCLUDES:</h3>
                    <p className="includes_Data">
                      <CheckMark />{" "}
                      <span className="fontSize14">
                        Lorem ipsum dolor sit amet.
                      </span>
                    </p>
                    <p>
                      <CheckMark />{" "}
                      <span className="fontSize14">
                        Lorem ipsum dolor sit amet, <br /> consectetur
                        adipiscing elit.
                      </span>
                    </p>
                  </div>
                  <div>
                    <button
                      className="userBtn btn-theme fontSize18"
                      onClick={handleEditPlanShow}
                    >
                      {t("payment.edit")}
                    </button>
                  </div>
                </div>
                <div className="plan-parent d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="plan_Txt fontSize20">plan 1</h3>
                    <h3 className="freeTxt fontSize24">
                      $500<span className="fontSize16">/month</span>
                    </h3>
                    <p className="fontSize16">Last Updated: 10 Jun 2023</p>
                  </div>
                  <div className="includes_Parent">
                    <h3 className="includerTxt fontSize14">INCLUDES:</h3>
                    <p className="includes_Data">
                      <CheckMark />{" "}
                      <span className="fontSize14">
                        Lorem ipsum dolor sit amet.
                      </span>
                    </p>
                    <p>
                      <CheckMark />{" "}
                      <span className="fontSize14">
                        Lorem ipsum dolor sit amet, <br /> consectetur
                        adipiscing elit.
                      </span>
                    </p>
                  </div>
                  <div>
                    <button className="userBtn btn-theme fontSize18">
                      {t("payment.edit")}
                    </button>
                  </div>
                </div>
                <div className="plan-parent d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="plan_Txt fontSize20">plan 1</h3>
                    <h3 className="freeTxt fontSize24">
                      $5000<span className="fontSize16">/month</span>
                    </h3>
                    <p className="fontSize16">Last Updated: 10 Jun 2023</p>
                  </div>
                  <div className="includes_Parent">
                    <h3 className="includerTxt fontSize14">INCLUDES:</h3>
                    <p className="includes_Data">
                      <CheckMark />{" "}
                      <span className="fontSize14">
                        Lorem ipsum dolor sit amet.
                      </span>
                    </p>
                    <p>
                      <CheckMark />{" "}
                      <span className="fontSize14">
                        Lorem ipsum dolor sit amet, <br /> consectetur
                        adipiscing elit.
                      </span>
                    </p>
                  </div>
                  <div>
                    <button className="userBtn btn-theme fontSize18">
                      {t("payment.edit")}
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      {/* Start Create New Plan Modal */}
      <Modal
        show={show}
        onHide={handleClose}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Header className="newPlan_ModalHeader" closeButton={false}>
          <Modal.Title>{t("payment.createNewPlan")}</Modal.Title>
          <Button variant="link" onClick={handleClose} className="close_Btn">
            <CrossIcon />
          </Button>
        </Modal.Header>

        <Modal.Body className="newPlan_ModalBody">
          <form action="">
            <div className="createPlanform_Child">
              <label htmlFor="planName" className="companyLabel">
                {t("payment.planName")} <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="planName"
                className="commonInput"
                placeholder={t("payment.enterPlanName")}
                name="planName"
                value={values?.planName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <small className="text-danger ms-1">
                {touched.planName && errors.planName}
              </small>
            </div>
            <div className="createPlanform_Child">
              <label htmlFor="price" className="companyLabel">
                {t("payment.price")} <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                id="price"
                className="commonInput"
                placeholder={t("payment.enterPrice")}
                name="price"
                value={values?.price}
                min={1}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <small className="text-danger ms-1">
                {touched.price && errors.price}
              </small>
            </div>
            <div className="createPlanform_Child">
              <label htmlFor="name" className="companyLabel">
                {t("payment.addOn")} <span className="text-danger">*</span>
              </label>
              {values?.addOn?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="d-flex align-items-center my-2">
                      <input
                        type="text"
                        id="name"
                        className="commonInput me-2"
                        placeholder={t("payment.enterAddOn")}
                        name="name"
                        value={item?.name}
                        onChange={(e) => handleAddOn(e, index)}
                        onBlur={() =>
                          setFieldTouched(`addOn.${index}.name`, true)
                        }
                      />
                      {values?.addOn?.length - 1 == index ? (
                        <div
                          onClick={() => {
                            setFieldValue("addOn", [
                              ...values?.addOn,
                              { name: "" },
                            ]);
                          }}
                        >
                          <AddIcon className="addPlan_Icon" />
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            let data = [...values?.addOn];
                            data?.splice(index, 1);
                            setFieldValue("addOn", data);
                          }}
                        >
                          <CloseIcon className="addPlan_Icon" />
                        </div>
                      )}
                    </div>
                    <small className="text-danger ms-1">
                      {touched?.addOn?.at(index)?.name &&
                        errors?.addOn?.at(index)?.name}
                    </small>
                  </React.Fragment>
                );
              })}
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer className="newPlan_ModalFooter">
          <Button
            variant="secondary"
            className="userBtn btn-theme planModalFooter_Btn"
            onClick={handleSubmit}
            type="button"
          >
            {t("payment.create")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* End Create New Plan Modal */}

      {/* Start Create New Plan Modal */}

      <Modal
        show={editPlanShow}
        onHide={handleEditPlanClose}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Header className="newPlan_ModalHeader" closeButton={false}>
          <Modal.Title>{t("payment.editPlan")}</Modal.Title>
          <Button
            variant="link"
            onClick={handleEditPlanClose}
            className="close_Btn"
          >
            <CrossIcon />
          </Button>
        </Modal.Header>

        <Modal.Body className="newPlan_ModalBody">
          <form action="">
            <div className="createPlanform_Child">
              <label htmlFor="name" className="companyLabel">
                {t("payment.planName")} <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="name"
                className="commonInput"
                placeholder={t("payment.enterPlanName")}
              />
            </div>
            <div className="createPlanform_Child">
              <label htmlFor="name" className="companyLabel">
                {t("payment.price")} <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                id="name"
                className="commonInput"
                placeholder={t("payment.enterPrice")}
              />
            </div>
            <div className="createPlanform_Child">
              <label htmlFor="name" className="companyLabel">
                {t("payment.addOn")}
              </label>
              <div className="d-flex align-items-center">
                <input
                  type="text"
                  id="name"
                  className="commonInput me-2"
                  placeholder={t("payment.enterAddOn")}
                />
                <CloseIcon className="addPlan_Icon" />
              </div>
            </div>

            <div className="d-flex align-items-center">
              <input
                type="text"
                id="name"
                className="commonInput me-2"
                placeholder={t("payment.enterAddOn")}
              />
              <AddIcon className="addPlan_Icon" />
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer className="newPlan_ModalFooter">
          <Button
            variant="secondary"
            className="userBtn btn-theme planModalFooter_Btn"
          >
            {t("payment.create")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* End Create New Plan Modal */}
    </div>
  );
};

export default PaymentAndBilling;
