import React from "react";
import { IoChevronBackSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const NavigateBack = ({ children, path, handleClick }) => {
  const navigate = useNavigate();
  return (
    <h2
      className="mainhead font-24"
      onClick={() => (handleClick ? handleClick() : navigate(path ? path : -1))}
      role="button"
      key={Math.random()}
    >
      <IoChevronBackSharp size={30} />
      {children}
    </h2>
  );
};

export default NavigateBack;
