import { Navigate, Outlet } from "react-router-dom";
import useToken from "../hooks/useToken";
import useDetails from "../hooks/useDetails";

export const UserAuth = () => {
  const token = useToken();
  if (token) {
    return <Outlet />;
  } else {
    return <Navigate to="/" replace={true} />;
  }
};

export const PublicAuth = () => {
  const token = useToken();

  if (token) {
    return <Navigate to="/dashboard" replace={true} />;
  } else {
    return <Outlet />;
  }
};

export const CheckPermission = ({ permission }) => {
  const details = useDetails();
  if (details?.userPermissions[permission]) {
    return <Outlet />;
  } else {
    return <Navigate to="/not-allowed" replace={true} />;
  }
};
