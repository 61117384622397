import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    details: "",
  },
  reducers: {
    login: (state, action) => {
      return {
        ...state,
        details: { ...state.details, ...action.payload },
      };
    },
    logout: (state) => {
      return {
        ...state,
        details: {},
      };
    },
  },
});

export const { login,logout } = authSlice.actions;

export default authSlice.reducer;
