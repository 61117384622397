import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import {} from "../../SvgIcons/allIcons";
import DeleteModal from "../../components/DeleteModal";
import NavigateBack from "../../components/NavigateBack";
import useSlider from "../../hooks/useSlider";
import { deleteUser, getUserDetails } from "../../services/services";
import { toastAlert } from "../../utils/SweetAlert";
import Sidebar from "../sidebar/Sidebar";
import CheckPermission from "../../components/CheckPermission";
import { constant } from "../../utils/constants";

const Userdetail = () => {
  const isSlider = useSlider();
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [showDelete, setShowDelete] = useState(false);

  const { data } = useQuery({
    queryKey: ["user-details", id],
    queryFn: async () => {
      const resp = await getUserDetails(id, true);
      return resp?.data?.data;
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => deleteUser(id),
    onSuccess: (resp) => {
      setShowDelete(false);
      navigate(-1);
      toastAlert("success", resp?.data?.message);
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={6}>
            <NavigateBack> {t("user.userDetails")}</NavigateBack>
          </Col>
          <Col className="text-end" xl={6}>
            <span className="useridText font-14 colorDark">
              {t("user.custId")}:
            </span>
            <span className="useridText useridFeild font-14 colorDark">
              {data?.userId}
            </span>
            <CheckPermission
              permission={constant.PERMISSIONS.CAN_ADD_NEW_USERS}
            >
              <Link className="userBtn btn-theme" to={`../addNewUser?id=${id}`}>
                {t("globals.edit")}
              </Link>
              <button
                className="userBtn btn-theme dltBtn"
                type="button"
                onClick={() => setShowDelete(id)}
              >
                {t("globals.delete")}
              </button>
            </CheckPermission>
          </Col>
        </Row>
        <div className="tableOuter">
          <Row className="mb-5">
            <Col className="infoColumn" xl={3}>
              <p className="colorDark font-18">{t("user.companyName")}</p>
              <p>{data?.userCompany?.name}</p>
            </Col>
            <Col xl={1} className="CenterLine"></Col>
            <Col className="infoColumn" xl={3}>
              <p className="colorDark font-18">{t("user.brandName")}</p>
              <p>{data?.userBrand?.name}</p>
            </Col>
            <Col xl={1} className="CenterLine"></Col>
            <Col className="infoColumn" xl={3}>
              <p className="colorDark font-18">{t("user.role")}</p>
              <p className="text-capitalize"> {data?.role}</p>
            </Col>
            <Col className="infoColumn mt-5" xl={6}>
              <p className="colorDark font-18">{t("user.basicInfo")}</p>
              <p>
                {t("user.name")}:{" "}
                <span className="colorDark text-capitalize">
                  {data?.userInfo?.name}
                </span>
              </p>
              <p>
                {t("user.emailAddress")}:{" "}
                <span className="colorDark">{data?.email}</span>
              </p>
              <p>
                {t("user.phoneNumber")}:{" "}
                <span className="colorDark text-nowrap">
                  {data?.userInfo?.dialCode} {data?.userInfo?.mobile}
                </span>
              </p>
            </Col>
          </Row>

          <label className="font-18 colorDark pb-3">
            {t("brand.locations")}
          </label>
          <div className="table-responsive tableOut">
            <table className="table tableDashboard">
              <thead className="thead-dark">
                <tr>
                  <th>{t("user.name")}</th>
                  <th>{t("user.location")}</th>
                  <th>{t("user.added")}</th>
                </tr>
              </thead>
              <tbody>
                {data?.userDetails?.userLocations &&
                data?.userDetails?.userLocations?.length > 0 ? (
                  data?.userDetails?.userLocations?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item?.name}</td>
                        <td>
                          {item?.billingAddress?.fullAddress}{" "}
                          {item?.billingAddress?.street}{" "}
                          {item?.billingAddress?.city}{" "}
                          {item?.billingAddress?.state}{" "}
                          {item?.billingAddress?.zipCode}{" "}
                          {item?.billingAddress?.country}
                        </td>
                        <td>{moment(item?.createdAt).format("lll")}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      <h4>{t("brand.locationNotFound")}</h4>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={deleteMutation.mutate}
      />
    </div>
  );
};

export default Userdetail;
